<template>
  <div class="merchants">
    <PageHeader dilog :border="false" title="企业首营流程" center>
      <Steps :step="2" />
    </PageHeader>
    <div>
      <div
        :class="
          isMore
            ? 'TailoringAuto animation'
            : 'Tailoring animation'
        "
      >
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          size="mini"
          label-width="125px"
          class="demo-form-inline"
        >
          <div>
            <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" />申请信息</div>

            <el-form-item label="申请日期" class="eachitem">
              {{ info_temp.ApplyDateString }}
            </el-form-item>
            <el-form-item label="申请部门" class="eachitem">
              {{ info_temp.ApplyDepartment }}
            </el-form-item>
            <el-form-item label="申请人" class="eachitem">
              {{ info_temp.ApplyUser }}
            </el-form-item>
            <el-form-item label="填表人" class="eachitem">
              {{ info_temp.FillFormUser }}
            </el-form-item>
          </div>
          <div>
            <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 企业基本信息</div>
            <el-form-item label="企业名称" class="eachitem">
              {{ info_temp.CompanyName }}
            </el-form-item>
            <el-form-item label="企业简称" class="eachitem">
              {{ info_temp.CompanyAbbreviation }}
            </el-form-item>
            <el-form-item label="企业编号" class="eachitem">
              {{ info_temp.CompanyNo }}
            </el-form-item>
            <el-form-item label="企业类型" class="eachitem">
              {{ CompanyTypeformatter({ CompanyType: info_temp.CompanyType }) }}
            </el-form-item>
          </div>
          <div>
            <el-form-item label="联系人" class="eachitem">
              {{ info_temp.ContactName }}
            </el-form-item>
            <el-form-item label="联系电话" class="eachitem">
              {{ info_temp.ContactTel }}
            </el-form-item>
            <el-form-item label="传真" class="eachitem">
              {{ info_temp.Fax }}
            </el-form-item>
            <el-form-item label="邮箱" class="eachitem">
              {{ info_temp.Email }}
            </el-form-item>
          </div>
          <div>
            <el-form-item label="企业法人" class="eachitem">
              {{ info_temp.CorporationName }}
            </el-form-item>
            <el-form-item label="法人电话" class="eachitem">
              {{ info_temp.CorporationTel }}
            </el-form-item>
            <el-form-item label="社会统一信用代码" class="eachitem">
              {{ info_temp.CreditCode }}
            </el-form-item>
            <el-form-item label="被授权人" class="eachitem">
              {{ info_temp.AuthorizedPerson }}
            </el-form-item>
          </div>
          <el-form-item label="详细地址">
            {{ info_temp.Province }} {{ info_temp.City }} {{ info_temp.Area }} {{ info_temp.Street }}
          </el-form-item>
          <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 财务信息</div>
          <div>
            <el-form-item label="开户银行" class="eachitem">
              {{ info_temp.Bank }}
            </el-form-item>
            <el-form-item label="开户支行" class="eachitem">
              {{ info_temp.BranchBankProvince }} {{ info_temp.BranchBankCity }} {{ info_temp.BranchBankArea }} {{ info_temp.BranchBank }}
            </el-form-item>
            <el-form-item label="付款方式" class="eachitem">
              {{ info_temp.PayType }}
            </el-form-item>
            <el-form-item label="回款账期" class="eachitem">
              {{ info_temp.PaymentCollectionDays ? info_temp.PaymentCollectionDays:'' }}天
            </el-form-item>
            <el-form-item label="开票税率" class="eachitem">
              {{ getTaxInfo(info_temp.TaxRate) }}<span v-if="getTaxInfo(info_temp.TaxRate)" />
            </el-form-item>
            <el-form-item label="备注：" class="eachitem">
              {{ info_temp.Remark }}
            </el-form-item>
          </div>
        </el-form>
        <div class="t-right rer">
          <el-link v-if="!isMore" class="more cursor m-right-10" @click="() => (isMore = true)">更多</el-link>
          <el-link v-if="!isMore" class="more cursor" @click="setItem()">修改</el-link>
        </div>
        <div v-if="isMore" class="up flex j-center">
          <i class="el-icon-arrow-up" @click="() => (isMore = false)" />
        </div>
      </div>
      <PageHeader title="资质文件">
        <el-button size="mini" @click="uoLoad">上传证件</el-button>
        <el-button size="mini" type="danger" plain @click="delImgs(1, false)">批量删除</el-button>
        <el-button size="mini" type="primary" :loading="btnLoadiung" @click="LaunchApproval">{{ view_item.Status === 3 ? "重新发起" : "发起审核" }}</el-button>
        <el-button size="mini" type="primary" @click="back">保存并返回</el-button>
      </PageHeader>
      <div class="main_flex flex j-between">
        <div class="left min-border-right flex a-center p-tb-20">
          <!-- <el-upload
              class="upload-demo"
              drag
              action="https://jsonplaceholder.typicode.com/posts/"
              multiple
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload> -->
          <div
            v-for="(i, index) in tableData1"
            :key="index"
            style="position: relative"
            class="img_view m-tb-20"
          >
            <img :src="imgsUrl + i.PhotoUrl" @click="showPic">
            <div
              style="position: absolute; right: 5px; top: 5px; z-index: 10"
              class="cursor"
              @click="delImgs(i, true)"
            >
              x
            </div>
          </div>
        </div>
        <div class="right">
          <PageHeader :border="false" title="已上传的文件" />
          <div class="p-lr-20">
            <el-table
              ref="multipleTable"
              :border="false"
              :data="tableData1"
              height="400px"
              tooltip-effect="dark"
              @select-all="selectAll1"
              @select="select1"
            >
              <af-table-column type="selection" width="55" />
              <af-table-column
                prop="TypeString"
                label="证件类型"
                width="180"
              />
              <af-table-column
                prop="CompanyName"
                label="企业名称"
                width="180"
              />
              <af-table-column prop="CertificateCode" label="证件编号" width="250" />
              <af-table-column prop="ValidToString" label="证件有效期" />
              <af-table-column prop="Status" label="状态" />
              <af-table-column prop="address" label="操作" fixed="right" width="50">
                <template slot-scope="scope">
                  <el-popover
                    placement="left"
                    trigger="hover"
                    :width="50"
                    popper-class="test_pop_view"
                  >
                    <div>
                      <div
                        class="cursor"
                        @click="editImgs(scope.row)"
                      >
                        <i class="el-icon-edit p-right-10" />
                        <span>编辑</span>
                      </div>
                      <div
                        class="cursor"
                        @click="delImgs(scope.row, true)"
                      >
                        <i class="el-icon-delete p-right-10" />
                        <span>删除</span>
                      </div>
                      <div
                        class="cursor"
                        @click="downLoadImgs(scope.row)"
                      >
                        <i class="el-icon-download p-right-10" />
                        <span>下载</span>
                      </div>
                    </div>
                    <i slot="reference" class="el-icon-more" />
                  </el-popover>
                </template>
              </af-table-column>
              <div slot="empty">
                <!-- <div class="p-top-20">
                    <img src="../../assets/logo.png" alt="" width="140" height="140">
                  </div> -->
                <p :style="{ marginTop: '10px' }">表格数据为空</p>
              </div>
            </el-table>
          </div>
          <!-- <Foot /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Index from '@/minxin/merchants/index'
export default {
  name: 'UpLoadCif',
  mixins: [Index]
}
</script>
<style scoped lang='scss'>
@import "@/style/search.scss";
@import "@/style/business.scss";
@import '@/style/product.scss';
</style>
